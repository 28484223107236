/* html,
body #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
} */
/* Sidebar container */
.ps-sidebar-root {
  height: 100%;
}

.ps-sidebar-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.sidebar-container {
  position: fixed;
  display: flex;
  height: 100vh;
  /* background-color: #f8f9fa; */
  width: 250px;
  padding: 0; /* Remove padding from the container */
  /* display: flex;
  flex-direction: column;
  justify-content: flex-start; */
  flex-direction: column;
  background-color: #fff;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  align-self: stretch;
  /* color: #333;
  border-right: 1px solid #e0e0e0; */
  transition: width 0.3s ease;
}

/* Sidebar header */
.sidebar-header {
  padding: 20px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
}

.sidebar-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 10px;
}

/* Sidebar content */
.sidebar-content {
  padding: 20px 0; /* Remove horizontal padding */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto; */
}

.ps-menu-button {
  padding: 10px 15px !important;
}

/* Sidebar groups */
.sidebar-content-items-group-1,
.sidebar-content-items-group-2 {
  margin-bottom: 50px;
  /* margin-top: 20px; */
}

/* Footer with collapse button */
.sidebar-footer {
  position: absolute;
  bottom: 20px;
  width: 100%;
  padding: 0 15px;
  text-align: center;
}

/* Collapse button */
.collapse-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
  font-size: 14px;
  width: 100%;
  padding: 10px;
}

.collapse-btn span {
  margin-left: 10px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.collapse-btn:hover {
  background-color: #f0f0f0;
}

/* Collapsed state */
.ps-collapsed .sidebar-container {
  width: 80px;
}

.ps-collapsed .collapse-btn {
  justify-content: center;
}

/* Custom styles for hover effects, etc. */
/* .sidebar-container button:hover {
  background-color: #dde9ec;
} */

/* .sidebar-footer button {
    width: 150%;
    padding: 8px;
    background: none;
    border: none;
    cursor: pointer;
    bottom: 0;
}

.sidebar-footer {
    position: absolute;
    bottom: 0;
    float: center;
}

.sidebar-container {
    height: 100vh;
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
}

.sidebar-content-items-group-1 {
    margin-top: 50px;
}

.sidebar-content-items-group-2 {
    margin-top: 50px;
} */

.logo {
  width: 150px;
  height: auto;
  transition: width 0.3s ease;
  padding: 0 10px;
}

.logo-collapsed {
  width: 70px;
}

.sidebar-label {
  padding: 10px;
  height: 28px;
}

.sidebar-label-name {
  color: #808080;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
