.error-message {
    color: #dc2626;
    background-color: #fee2e2;
    padding: 0.75rem;
    border-radius: 0.375rem;
    margin: 1rem 0;
  }
  
  .success-message {
    color: #059669;
    background-color: #d1fae5;
    padding: 0.75rem;
    border-radius: 0.375rem;
    margin: 1rem 0;
  }