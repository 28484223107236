.modalBackground {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6); /* Darker overlay */
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
  }

  .legend-with-tooltip {
    display: inline-flex;
    align-items: center;
    gap: 0.5em; /* Adjust the gap to control the spacing between the legend text and tooltip */
  }
  
 
  .material-symbols-outlined {
    font-size: 12px; /* Adjust size to fit the design */
    cursor: pointer;
    vertical-align: middle;
  }

  
  .modalContainer {
    width: 40%; /* Modal width as a percentage of the viewport */
    max-width: 560px; /* Maximum width to prevent it from growing too large */
    min-width: 400px; /* Minimum width to prevent it from shrinking too much */
    height: 650px;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; 
    position: relative;
    overflow: visible;
    border: 1px solid black;
    
}

/* Optional: Use media queries to adjust behavior on smaller screens */
@media (max-width: 600px) {
    .modalContainer {
        width: 90%; /* Use a larger percentage for smaller screens */
        height: auto; /* Adjust height dynamically for smaller screens */
    }
}

  
  
.modalContainer .title {
  flex: 0 1 20%;
  position: absolute; /* Allow precise placement */
  top: 0; /* Align to the top of the container */
  left: 0; /* Optional: Align to the left */
  margin: 0; /* Remove default margins */
   /* Optional: Add padding for spacing */
  font-size: 2; /* Adjust font size */
  font-weight: normal;
  padding-left: 30px;
  
}

  .modalContainer .body {
    display: flex;
    flex: 1;
    margin-top: 50px;
    margin-bottom: 50px;
    justify-content: space-between; /* Space between left and right sides */
    background-color: F7F8FA;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: EBEEFA;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: EBEEFA;

   
  }
  
  .left-side {
    width: 50%; /* Take up 40% of the modal for the left side */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 30px;
    padding-top: 20px;
    padding-right: 30px;
    padding-bottom: 20px;
    
  }
  
  .right-side {
    width: 50%; /* Right side takes up 50% */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 60px;
    padding-top: 20px;
    padding-right: 0px;
    padding-bottom: 5px;
  }
  
  .radio-container {
    display: flex;
    flex-direction: column; /* Stack radio buttons vertically */
    align-items: flex-start; /* Align radio buttons to the left */
    margin-top: 0;
    margin-bottom: 2px;
  }
  
  .input-radio {
    margin-right: 10px; /* Space between radio button and text */
    /* Optionally, adjust size of radio buttons if needed */
  }

  .input-incentives {
    padding: 0.5rem;
    font-size: 15px;
    font-family: 'Inter', sans-serif;
    font-weight: normal;
    color: black;
    border-radius: 50px;
    border-color: #6372ff;
    border-style: solid;
    width: 85%; /* Make inputs fill the available width */
    margin-bottom: 10px; /* Add spacing between inputs */
    box-sizing: border-box; /* Include padding and border in element's total width */
    text-align: right;
  }
  
  .label-right {
    margin-bottom: 0.5rem;
    font-family: 'Inter';
    color: #374151;
    font-weight: bold;
    font-size: 13;
    display: block; /* Ensure labels take up full width of container */
  }

  .label-left {
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 11px;
    color: #374151;
    display: flex;
    align-items: center; /* Center the radio button and label text vertically */
  }


.label-left .input-radio {
  margin-right: 10px; /* Add space between radio button and text */
}

  legend{
    font-family: 'Inter';
    font-size: 13;
    font-weight: bold;
    color: #374151;
    margin-bottom: 10px;

  }
  
  .modalContainer .footer {
    flex: 0 1 20%;
    display: flex;
    justify-content: flex-end; /* Position buttons to the bottom right */
    align-items: center;
    width: 100%;
    height: 30px;
    padding: 10px;
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
  
  .modalContainer .footer button {
    font-family: 'Inter', sans-serif;
    width: 65px;
    height: 30px;
    margin-top: 35px;
    margin-bottom: 15px;
    margin-left: 10px;
    border: none;
    border-radius: 8px;
    font-size: 13px;
    cursor: pointer;
  }
  
  #cancelBtn {
    background-color: #F7F8FA;
    color: black;
    
  }

  #submitBtn {
    background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
    color: white;
  }
  
  button:hover {
    background-color: 5E5ADB;
  }


.incentive-total-section {
  margin-top: 30px; /* Adjust this value to move the section lower */
}
