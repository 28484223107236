@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");

.menu-item {
  display: flex;
  align-items: center;
  padding: 10px 15px; /* Match padding with sidebar header */
  height: 48px;
  border-radius: 8px;
  transition: background-color 0.3s, color 0.3s;
  text-decoration: none;
  color: #2c2c2c;
  margin: 5px 0;
}

.menu-item-content {
  display: flex;
  align-items: center;
  gap: 15px;
}

.menu-item:hover {
  background-color: #f0f0f0;
}

.menu-item-active {
  background-color: #e8f0fe;
  color: #007bff;
}

.menu-item svg {
  display: flex;
  width: 18px;
  height: 18px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.menu-item span {
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ps-collapsed .menu-item {
  padding: 10px 15px; /* Keep consistent padding when collapsed */
  justify-content: flex-start; /* Align to the left when collapsed */
}
