@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* .container-flex-row {
  display: flex;
  height: 100vh;
  padding: 0;
  align-items: flex-start;
  gap: 32px;
}

.container-flex-col {
  padding: 0;
  width: 100%;
  height: 100%;
  margin: 0 25px 0 0;
} */

/* .content-wrapper {
  max-width: 1200px;
  margin: 0 auto;
}

.content-wrapper h1 {
  margin-bottom: 20px;
} */

.map-dashboard-container {
  height: 50vh;
  /* position: fixed; */
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}

.dashboard-container {
  /* height: calc(360px - 50%); */
  display: flex;
  flex-direction: column;
}

.map-dashboard-container h1 {
  font-size: 26px;
  margin: 20px 0;
}

.table-container-wrapper {
  /* Add any specific styles for the table wrapper if needed */
}

.pro-calculator {
  display: flex;
  height: 100vh;
  padding: 32px 32px 0px 32px;
  align-items: flex-start;
  gap: 32px;
}

.pro-calculator-content {
  padding: 0 0;
  width: 100%;
}

/*

.column{
  float: left;
  padding: 5px 100px;
}

a.button {
  padding: 10px 25px;
  border: 2px outset buttonborder;
  border-style: solid;
  border-radius: 20px;
  border-color: #ffcd94;
  background-color: white;
  text-decoration: none;
  position: relative;
  margin: 25px 250px;
  margin-top: 100%;
  color: black;
  font-size: 12px;
}

a.button:hover {
  background-color: #ffad60;
}

.center {
  margin: auto;
  text-align: center;
  width: 50%; 
  padding: 10px;
}


legend {
  border-color: #ffcd94;
  border-style: solid;
  border-radius: 20px;
  padding: 10px 20px;
  padding-bottom: 30px;
  margin-left: 75px;
  margin-right: 75px;
  margin-bottom: 20px;
  list-style-type: none;
}

button.button{
  position: relative;
  margin: 25px 250px;
  border-color: #ffcd94;
  border-style: solid;
  border-radius: 20px;
  padding: 10px 25px;
  background-color: white;
}

button.button:hover {
  background-color: #ffad60;
}

h1, h2{
  text-align: center;
}

input[type=number]{
  -moz-appearance: textfield;
}

input[type=number]::-webkit-outer-spin-button, 
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.table {
  display: grid;
  grid-gap: 6px; 
  border: 1px solid transparent;
}

.unit {
  white-space: nowrap;
}

.row {
  display: grid; 
  border: 1px solid transparent;
}

[data-row="2"] {
  grid-template-columns: 1fr 1fr; 
  grid-gap: 16px; 
}

[data-row="3"] {
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

label.label{
  font-weight: bold;
}

label.label-description {
  font-style: italic;
}

p.TCO {
  font-weight: bold;
  font-size: larger;
}

ul {
  list-style-type: none;
}

.userFormContainer {
  text-align: center;
}

.form {
  padding: 25px 25px;
  border-radius: 20px;
  border-style: solid;
  border-color: #ffcd94;
  margin-top: 25px;
  padding-bottom: 75px;
}

.form-group {
  display: block;
  padding: 20px 20px;
}

.form-question {
  float: left;
  padding-left: 25px;
}

.form-input {
  float: right;
  border-radius: 20px;
  border-style: solid;
  border-color: #ffcd94;
  padding: 8px 12px;
  margin: auto;
}

p.description {
  text-align: center;
} */
