.optimize-btn {
    font-family: Inter;
    position: relative;
    height: 32px;
    width: 200px;
    background-color: #5E5ADE;
    border: none;
    border-radius: 6px;
    color: #fff;
}

.btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.truck-presets {
    width: 100%;
    font-family: Inter;
    border-collapse: collapse;
    table-layout: fixed;
    text-align: center;
    display: flex;
    flex-direction: column;
}