.add-button-custom {
    font-family: Inter;
    position: relative;
    height: 32px;
    width: 200px;
    background-color: #5E5ADE;
    border: none;
    border-radius: 6px;
    color: #fff;
}

.btn-container .top-right {
    left: 85%;
    top: 0px;
}

.btn-container .bottom-left {
    position: absolute;
    bottom: 50px;
    left: 300px;
}

.btn-container svg {
    display: inline-block;
    height: 2em;
    vertical-align: -0.65em;
}

.popup-title {
    font-family: Inter;
    color: #4E5A82;
    padding-bottom: 25px;
    padding-left: 10px;
}

.popup-content {
    display: flex;
    flex-direction: column;
}

.popup-input-group-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.popup-input-group {
    width: 95%;
    margin-bottom: 15px;
    position: relative;
}

.popup-input-group-label {
    color: #4E5A82;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 10px;
}

.popup-input {
    border: 1px solid #808080;
    border-radius: 10px;
    font-family: Inter;
    color: #4E5A82;
    height: 30px;
    padding-left: 10px;
    width: 100%;
}

.popup-input-route {
    border: 1px solid #808080;
    border-radius: 10px;
    font-family: Inter;
    color: #4E5A82;
    height: 30px;
    padding-left: 10px;
    width: 50%;
}

.add-button-container {
    right: 40%;
    position: absolute;
    bottom: 25px;
    align-items: center;
}

.add-button-popup {
    font-family: Inter;
    position: relative;
    height: 32px;
    width: 100px;
    background-color: #5E5ADE;
    border: none;
    border-radius: 6px;
    color: #fff;
}

.add-button-container svg {
    display: inline-block;
    height: 2em;
    vertical-align: -0.6em;
}

.popup-input-group .relative {
    width: 100%;
}

.popup-input-group .absolute {
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
}

/* Address input specific styles */
.loading-spinner {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.spinner {
    width: 16px;
    height: 16px;
    border: 2px solid #5E5ADE;
    border-top: 2px solid transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.address-suggestions {
    position: absolute;
    top: calc(100% - 5px);
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #808080;
    border-radius: 6px;
    margin-top: 4px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.suggestion-item {
    padding: 8px 12px;
    cursor: pointer;
    font-family: Inter;
    color: #4E5A82;
}

.suggestion-item:hover {
    background-color: #f5f5f5;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.error-message {
    color: #ff0000;
    font-size: 0.8rem;
    margin-top: 4px;
}

.add-button-custom:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.add-button-custom.delete {
    background-color: #ff4444;
}

.add-button-custom.delete:hover {
    background-color: #ff0000;
}

/* Buttons */
button.add-route-btn, button.remove-route-btn{
    background-color: white;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    /* Adjust space between icon and text */
    border: none;
    background-color: transparent;
    font-size: 16px;
    cursor: pointer;
    color: #4E5A82;
}

.stop-row {
    display: flex;
    align-items: center;
    gap: 10px;
    /* Space between elements */
    margin-bottom: 10px;
    /* Add spacing between rows */
}