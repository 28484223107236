.table-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
}

.dropdown-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.fleet-header {
  font-size: 18px;
  color: #4E5A82;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  margin-right: 10px;
}

#fleet-select {
  background: white;
  border: 2px solid #4E5A82;
  border-radius: 10px;
  padding: 8px 15px;
  font-size: 14px;
  color: #4E5A82;
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-family: Arial, sans-serif;
}

.table-header th {
  background-color: #f8f9fa;
  color: #687182;
  font-weight: 550;
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid #e5e7eb;
  font-size: 12px;
  text-transform: uppercase;
}

.table-content td {
  padding: 12px;
  border-bottom: 1px solid #dee2e6;
  color: #5e5adb;
  font-size: 14px;
}

.table-content:last-child td {
  border-bottom: none;
}

.table-content:nth-child(even) {
  background-color: #f8f9fa;
}

/* Add these new styles */
.table-content td:first-child {
  color: #6b7280;
  font-weight: 600;
}

.status-active {
  color: #10b981;
  font-weight: 600;
}

.status-inactive {
  color: #6b7280;
  font-weight: 600;
}

.add-button {
  background-color: #6366f1;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.add-button:hover {
  background-color: #4f46e5;
}

.truck-checkbox {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

/* Adjust the first column (checkbox column) width */
.table-header th:first-child,
.table-content td:first-child {
  width: 40px;
  text-align: center;
}

/* Adjust the ID column style since it's no longer the first column */
.table-content td:nth-child(2) {
  color: #6b7280;
  font-weight: 600;
}
