.table-container {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 50vh;
  overflow: hidden;
  position: relative;
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-family: Arial, sans-serif;
  position: relative;
}

/* Make thead fixed */
.table thead {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
}

/* Adjust tbody to scroll independently */
.table tbody {
  display: block;
  max-height: calc(50vh - 140px);
  overflow-y: auto;
  scroll-behavior: smooth;
  position: relative;
}

/* Keep table header row full width */
.table thead tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

/* Keep table content rows full width */
.table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

/* Make table title fixed */
.table-title {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 3;
  margin-bottom: 15px;
}

.table-header th {
  position: sticky;
  top: 0;
  background-color: #f8f9fa;
  color: #687182;
  font-weight: 550;
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid #e5e7eb;
  font-size: 12px;
  text-transform: uppercase;
  z-index: 2;
}

.table-content td {
  padding: 12px;
  border-bottom: 1px solid #dee2e6;
  color: #5e5adb;
  font-size: 14px;
}

.table-content:last-child td {
  border-bottom: none;
}

.table-content {
  background-color: white;
}

.table-content:nth-child(even) {
  background-color: white;
}

.charger-checkbox {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.table-header th:first-child,
.table-content td:first-child {
  width: 40px;
  text-align: center;
}

.table-content td:nth-child(2) {
  color: #6b7280;
  font-weight: 600;
}

.view-button {
  background-color: #6366f1;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-button:hover {
  background-color: #4f46e5;
}

.table-content td:nth-child(3) {
  color: #6366f1;
  font-weight: 600;
}

.charger-header {
  font-size: 18px;
  color: #374151;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

.table-content.selected {
  background-color: #f0f4ff !important;
  transition: background-color 0.3s ease;
}

/* Ensure the striped background doesn't override our selection */
.table-content.selected:nth-child(even) {
  background-color: #f0f4ff !important;
}

.table-container tbody {
  scroll-behavior: smooth;
}
