.loading-overlay {
    position: absolute;
    top: 40%;
    left: 50%;
    width: 100%;
    height: 100%;
    /* background-color: #CCC; */
}

.spinner {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.spinner-text{
    font-family: Inter;
    font-size: 14pt;
    color: #333;
    padding: 15px;
}