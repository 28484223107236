.route-results {
    width: 50%;
}

.map-results {
    width: 50%;
}

.total-tco {
    width: 100%;
} 

.tco-breakdown {
    width: 100%;
}

.run-history {
    width: 100%;
    height: 20%;
}

span.category-title {
    font-size: 14pt;
    color: #333;
}

p {
    font-size: 14pt;
}