.table-select-button{
    font-family: Inter;
    font-size: 12pt;
    position: relative;
    height: 32px;
    width: 200px;
    background-color: #5E5ADE;
    border: none;
    border-radius: 6px;
    color: #fff;
    margin: 10px;
}

.table-select-button.selected{
    border: 3px solid #8683e6;
    background-color: #8683e6;
}

.info-button {
    border: none;
    background-color: #FFF;
    color: #333;
    font-family: Inter;
    padding: 5px;
    margin: 10px;
}

.info-button svg {
    display: flex;
    width: 18px;
    height: 18px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}