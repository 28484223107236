body {
  font-family: 'Roboto', sans-serif; /* Apply font globally */
}

.image-slider {
  width: 100%;
  max-width: 80%;
  margin: 0 auto;
  text-align: center;
}

.slider-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  overflow: hidden; /* Ensures no overflow in any direction */
  width: 100%;
}

.images-wrapper {
  display: flex;
  transition: transform 0.3s ease-in-out;
  scroll-behavior: smooth;
  overflow: hidden; /* Ensure no scroll bars appear */
  width: 100%; /* Take full width of the slider container */
}

.slider-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Ensure each image takes up full width */
}

.slider-image {
  width: 100%; /* Adjust width to container */
  height: 100px; /* Fixed height for uniformity */
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.3s ease, transform 0.3s ease;
  object-fit: contain; /* Maintain aspect ratio */
}

.slider-image.active {
  opacity: 1;
  transform: scale(1.1);
}

.arrow-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #a9bdc7;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 20px;
  z-index: 10;
  transition: background-color 0.3s ease, transform 0.3s ease, color 0.3s ease;
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}

.arrow-button:hover { 
  background-color: #4797bf; /* Change background color on hover */
  transform: translateY(-50%) scale(1.1); /* Slight enlargement on hover */
}

.image-info {
  border-top: 1px solid #ccc;
  padding-top: 16px;
}

.image-info h3 {
  margin: 10px 0;
  font-size: 16pt;
}

.image-info p {
  font-size: 16px;
  color: #333;
}
